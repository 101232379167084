import React from 'react'
import '../../css/common/PopUp.css';

/*
import * as SocketIO from "socket.io-client";
var socket = SocketIO.connect();
*/

export default function AskIfEmailNeededPopUp(props) {

    async function NoEmailAnswerNeeded () {
        /*socket.emit(
            'no_email_answer_needed',
            {
              "user_code": props.user,
            }
        )*/
        const response = await fetch('/api/close_case', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "session": props.user,
            })
        });
            
        const response_data = await response.json();
        //console.log(response_data)


    }

    return (
        <div >
            {props.show && 
                <div className="PopUp"> 
                    <div className="PopUpTextContainer"> 
                        <div className="Header"> 
                            Vad bra att jag kunde hjälpa dig! 
                        </div>
                        <div className="Text"> 
                            Du har skickat in ett mejl till Landeryds golfklubb.
                        </div>
                        <div className="Text"> 
                            Om du fått svar på din fråga, kan jag meddela mina kollegor att de inte behöver svara på mejlet?
                        </div>
                        <div className="ButtonsContainer">                    
                            {<button onClick={ () => {props.closePopUp(); NoEmailAnswerNeeded()} } type="submit" className="ButtonNoEmail">
                                Jag har fått svar på det jag frågade efter i mejlet. Behöver inte svar.
                            </button>}
                            {<button onClick={ () => {props.closePopUp()} } type="submit" className="ButtonNeedEmail">
                                Jag vill fortfarande ha svar på mitt mejl.
                            </button>}
                            
                            
                        </div>
                    </div> 
                </div>}
        </div>
    )
}
