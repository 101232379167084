import React, {useState} from 'react'
import logo from '../../media/landeryd-logo-experiences.svg'
import '../../css/mobile/MobileHeaderFooter.css';
import GeneralInformation from '../common/GeneralInformation'
import Tips from '../common/Tips'

export default function MobileHeader() {
    const [ShowHelp, setShowHelp] = useState(false);

    return (   
        <div className="MobileHeader">
            <div className="MobileHeaderInside">
                <a className="MobileHeaderLogo" target="_blank" href="https://www.landerydsgolf.se">
                <img src= {logo} alt="Golfa i Linköping, Landeryds Golfklubb Östergötland"  height="50" href="https://www.landerydsgolf.se">
                </img>
                </a>
                {<button onClick={ () => setShowHelp(!ShowHelp) } type="submit" className="MobileHeaderButton">
                        {ShowHelp
                        ?   <span className="MobileHeaderText"> Göm info och tips </span>
                        :   <span className="MobileHeaderText"> Visa info och tips </span>}
                </button>}
                {ShowHelp && <div className="MobileInfoBox">
                    <GeneralInformation /> 
                    <Tips /> 
                    {<button onClick={ () => setShowHelp(false) } type="submit" className="MobileHeaderButtonSecond">
                        <span> Göm info och tips </span>
                        </button>}
                    </div>}     
            </div>
        </div>
       
    )
}
