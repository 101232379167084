import React, {useState}  from 'react'
import GeneralInformation from '../common/GeneralInformation'
import Tips from '../common/Tips'
import '../../css/web/WebSideBar.scss';
import '../../css/App.css';


export default function WebRightSideBar() {
    const [ShowHelp, setShowHelp] = useState(false);

    return (
        <div className="sideBar" >
            <div className="InfoBoxSideBar">
                <GeneralInformation />

                <div>                   
                    
                    {<button onClick={ () => setShowHelp(!ShowHelp) } type="submit" className="button">
                        {ShowHelp
                        ?   <span> Göm tips </span>
                        :   <span> Visa tips </span>}
                    </button>}
                    
                </div>

                {ShowHelp && <Tips />}        
            </div>
                   
        </div>
    )
}
