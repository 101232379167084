import React, {useState, useEffect, useLayoutEffect} from 'react'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import ReactDOM from 'react-dom';
import MediaQuery from 'react-responsive';

//Components
import MobileChatArea from './components/mobile/MobileChatArea'
import MobileHeader from './components/mobile/MobileHeader'
import WebHeader from './components/web/WebHeader'
import WebLeftSideBar from './components/web/WebLeftSideBar'
import WebRightSideBar from './components/web/WebRightSideBar'
import WebChatArea from './components/web/WebChatArea'
import Footer from './components/common/Footer'
import AskIfEmailNeededPopUp from './components/common/AskIfEmailNeededPopUp'
import AdminDashboard from './components/common/AdminDashboard'
import BookingPage from './components/common/BookingPage'
//Css
import './css/App.css';
import './css/admin/AdminComponents.scss';
import './css/admin/BookingPageComponents.scss';

//For socket:
//import * as SocketIO from "socket.io-client";
import AllMessages from './components/common/AllMessages';
import GDPRPopUp from './components/common/GDPRPopUp';
import MainContent from './components/common/MainContent';
//var socket = SocketIO.connect();


export default function App(props) { 

  
  
  //---------------------
  //     Declaration
  //---------------------
  const [allMessages, setallMessages] = useState([{
    'text': "Hejsan! Jag heter Landy. Vad handlar ditt ärende om?", 
    'from': "Landeryds AI-Robot"
  }])
  const [session, setsession] = useState(null)
  const [user, setuser] = useState(props.user_info)
  const [showAskIfEmailNeededPopUp, setshowAskIfEmailNeededPopUp] = useState(false)
  
  const [yesNoQuestion, setyesNoQuestion] = useState(false)
  const [buttonReplies, setbuttonReplies] = useState(false)

  //---------------------
  //       Socket
  //---------------------

  useEffect(() => {
    /*
    socket.on('robot_reply', (message) => {
      setallMessages((prevAllMessages) => [...prevAllMessages, message])
      setyesNoQuestion(message["yes_no_question"])
      setbuttonReplies(message["button_replies"])
    })

    socket.on('ask_if_answer_needed_via_email', (message) => {
      if (user) {
        setshowAskIfEmailNeededPopUp(true)
      }
    })
    */
  }, [])


  async function sendMessage (message, user) {
    try {
      let user_code = session
      if (user_code == null) {
        user_code = user
      }


      const response = await fetch('/get_answer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "text": message,   
          'session': user_code,
          'all_messages': allMessages,
        })
      });
      
      const response_data = await response.json();
      //console.log(response_data)
      setallMessages((prevAllMessages) => [...prevAllMessages, response_data])
      setyesNoQuestion(response_data["yes_no_question"])
      setbuttonReplies(response_data["button_replies"])
      setshowAskIfEmailNeededPopUp(response_data["ask_for_closing_case"])

      if (session == null && response_data["session"] != null) {
        setsession(response_data["session"])
      }
      
    } catch (error) {
      // Handle the error
    }
    /*socket.emit(
      'send_message',
      {
        "text": message,   
        'session': session,
        'all_messages': allMessages,
        'user': user
      }
    )*/
  }

  function handleSubmit (event, message, user) {

    event.preventDefault()

    if (message) {

      const new_message = {
        'text': message, 
        'from': 'Användare'
      }
      setallMessages((prevAllMessages) => [...prevAllMessages, new_message])
      sendMessage(message, user)
    }
  }

  //----------------------------

      
   

  return (
      <Router>
      <div className="App">
      
        <MediaQuery query="(max-width: 950px)">
          <MobileHeader />
        </MediaQuery>

        <MediaQuery query="(min-width: 950px)">
          <WebHeader />
        </MediaQuery>
        
        


        <Routes>
        
          {/*<Route exact path={["/", "/from_email/:user_code"]} render={props => (
            <React.Fragment>
          <GDPRPopUp              show={showGDPRPopUp} 
                                        closePopUp={() => setshowGDPRPopUp(false)}
                                        user = {user}/>
          <AskIfEmailNeededPopUp  show={showAskIfEmailNeededPopUp} 
                                  closePopUp={() => setshowAskIfEmailNeededPopUp(false)}
                                  user = {user}/>
            </React.Fragment>
          )}/>
          */}
          

          <Route path="/admin"  element={<AdminDashboard />} />
          <Route exact path={"/book_meeting"} element={<BookingPage />} />
          <Route exact path={"/book_meeting/:session"} element={<BookingPage />} />
          
          <Route exact path="/" element={<MainContent 
              buttonReplies={buttonReplies}
              yesNoQuestion={yesNoQuestion}
              allMessages={allMessages}
              handleSubmit={handleSubmit}
              showAskIfEmailNeededPopUp={showAskIfEmailNeededPopUp}
              setshowAskIfEmailNeededPopUp={setshowAskIfEmailNeededPopUp}

            />} />

          <Route exact path="/from_email/:user_code" element={<MainContent 
              buttonReplies={buttonReplies}
              yesNoQuestion={yesNoQuestion}
              allMessages={allMessages}
              handleSubmit={handleSubmit}
              showAskIfEmailNeededPopUp={showAskIfEmailNeededPopUp}
              setshowAskIfEmailNeededPopUp={setshowAskIfEmailNeededPopUp}
            />} />
        
        </Routes>
          
      </div>
        
      
    </Router>
  );
  
}


/*
const [state, setState] = useState({})

  useEffect(() => {
    fetch("/api").then(response => {
      if(response.status == 200){
        console.log(response)
        return response.json()
      }
    }).then(data => setState(data))
    .then(error => console.log(error))
  }, [])
*/