import React from 'react'
import logo from '../../media/landeryd-logo-experiences.svg'
import LanderydPicture from '../../media/Header_LANDERYD_CAROLINA_BYRMO_18.png';
import '../../css/web/WebHeaderFooter.scss';

export default function WebHeader() {

    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    var HeaderImage = <img src= {LanderydPicture} />


    if(isIE) {
        HeaderImage = <div class="IEHeaderImage" style={{backgroundImage: "url(" + LanderydPicture + ")"}}></div>
    }



    return (
        <div>
            <div className="WebHeader">
                <div className="WebHeaderInside">
                    <div className="WebHeaderInsideContainer">
                        <a target="_blank"  href="https://www.landerydsgolf.se">
                            <img src= {logo} alt="Golfa i Linköping, Landeryds Golfklubb Östergötland" width={"170px"}  height="55px" href="https://www.landerydsgolf.se">
                            </img>
                        </a>

                        <div className="HeaderMenu">
                            <a target="_blank" href="https://www.landerydsgolf.se" className="HeaderMenuLink">
                                Gå till hemsidan
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="WebHeaderBeneath">
            </div>
            
            <div className="HeaderImage" >
                {HeaderImage}
            </div>
            
        </div>
    )
}
