import React, {useState, useEffect} from 'react'
import EditBookingTime from "./EditBookingTime"

export default function BookingTimeToday(props) {
    const [editMode, seteditMode] = useState(false)

    const bookerInfo = props.bookingTimeInfo.booker_info.map((booker, index) => (
        <div className="Booker">
            <div className="Important">
                Möte {index+1}
            </div>
            <div>
                E-post: {booker.email} 
            </div>
            <div>
                Telefon: {booker.phone} 
            </div>
        </div>
    ))

    return (
        <div >
            <div>{props.bookingTimeInfo.date} </div>
            <div className="Important"> Tid: {props.bookingTimeInfo.time} </div>
            <div className="Important"> Antal möten: {props.bookingTimeInfo.booked_meetings} </div>
            {bookerInfo}
            {!editMode && <div>
                <button onClick={ () => seteditMode(true) } type="submit" className="EditShowButton">
                    Redigera
                </button>
            </div>}
            {editMode && <EditBookingTime seteditMode={seteditMode} onSuccess={() => props.getBookings()} bookingTimeInfo={props.bookingTimeInfo} />}
                
        </div>
    )
}
