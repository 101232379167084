import React, {useState, useEffect} from 'react'
import EditBookingTime from "./EditBookingTime"

export default function BookingTime(props) {
    const [showBookerInfo, setshowBookerInfo] = useState(false)
    const [editMode, seteditMode] = useState(false)

    const bookerInfo = props.bookingTimeInfo.booker_info.map((booker, index) => (
        <div className="Booker">
            <div className="Important">
                Möte {index+1}
            </div>
            <div>
                E-post: {booker.email} 
            </div>
            <div>
                Telefon: {booker.phone} 
            </div>
        </div>
    ))

    return (
        <div>
            <div className="BookingTime">
                <div className="Cell"> <div>{props.bookingTimeInfo.date}</div> </div>
                <div className="Cell">  <div>{props.bookingTimeInfo.time} </div></div>
                <div className="Cell">  <div> {props.bookingTimeInfo.max_meetings}</div> </div>
                <div className="Cell">  <div>{props.bookingTimeInfo.booked_meetings}</div> </div>
                <div className="Cell"> <div>
                {props.bookingTimeInfo.booked_meetings 
                    ? 
                        <button onClick={ () => setshowBookerInfo(!showBookerInfo) } type="submit" className="EditShowButton">
                            {showBookerInfo
                            ?   <span  className="Important"> Göm bokningar </span>
                            :   <span> Visa bokningar </span>}
                        </button> 
                    : <span></span>
                    }
                    </div>
                </div>
                <div className="Cell"> <div>
                {!editMode  
                    ? <button onClick={ () => seteditMode(true) } type="submit" className="EditShowButton">
                        Redigera
                    </button>
                    : <span></span>
                }
                </div>
                </div>
                
            </div>    
                    
            
            {showBookerInfo && bookerInfo}
            {editMode && <EditBookingTime seteditMode={seteditMode} bookingTimeInfo={props.bookingTimeInfo} onSuccess={() => props.getBookings()}/>}
        </div>
    )
}
