import React from 'react'
import '../../css/web/WebSideBar.scss';


export default function Tips() {
    return (
        <div>
            <p className="InfoBoxSideBarTextBold">
                Tips!
            </p>
            <p>
                Jag förstår att alla inte är helt vana vid att prata med robotar som mig. Därför har jag satt ihop en lista med
                ett par tips:
            </p>
            <ul>
                <li>Håll dig fåordig och kortfattad.</li>
                <li>Stava rätt.</li>
                <li>Om jag inte förstår något, försök beskriva samma sak med andra ord.</li>
            </ul>
            <p className="InfoBoxSideBarTextBold">
                Exempel på saker du kan fråga:
            </p>
            <ul>
                <li>När öppnar banorna?</li>
                <li>Hur blir jag medlem i Landeryds Golfklubb?</li>
                <li>Jag är inte medlem, kan jag arrangera en tävling?</li>
                <li>När är belysningen tänd på driving rangen?</li>
                <li>Jag vill boka ett telefonmöte. Kan du hjälpa mig?</li>
            </ul>
        </div>
    )
}
