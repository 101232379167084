import React, {useState, useEffect} from 'react'
/*
import * as SocketIO from "socket.io-client";
var socket = SocketIO.connect();
*/
export default function AddNewMeeting(props) {
    const [date, setdate] = useState(false)
    const [showNewBooking, setshowNewBooking] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    function getNextDate() {
        //socket.emit("get_next_date", true)
    }

    if (!date) {
        getNextDate()
    }

    async function addNewMeeting() {
        /* socket.emit(
            'add_new_meeting',
            {
              "date": date,
            }
        )*/
        const response = await fetch('/api/add_new_meetings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "date": date,
            })
        });
            
        const response_data = await response.json();
        //console.log(response_data)
        setshowNewBooking(false) 
        props.onSuccess()
    }

    useEffect(() => {
        async function fetchData() {
          try {
            const response = await fetch('/api/next_date_for_new_meetings');
            const data = await response.json();            
            //console.log(data)
            setdate(data)

          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false);
          }
        }
        fetchData();
      }, []);

    return (
        
        <div className="AddNewMeeting">
                <div>
                    {<button onClick={ () => setshowNewBooking(!showNewBooking) } type="submit" className="EditShowButton">
                                {!showNewBooking
                                ?   <span> Lägg till </span>
                                :   <span> Avbryt </span>}
                            </button>}
                </div>

                {showNewBooking && <div className="EditBookingTime">
                        <div className="PropertyRows">
                            <div className="Property"> <div>Datum:</div> <input 
                                            className="EditInput"
                                            type="text" 
                                            name="date"
                                            autoComplete="off"
                                            value={date}
                                            onChange={(e) => setdate(e.target.value)} 
                                            id="date"/> 
                            </div>
                        </div>
                        
                        <div>
                            <button onClick={ () => addNewMeeting() } type="submit" className="SaveButton">
                                Lägg till
                            </button>
                        </div>
                    </div>
                }
                
        </div>
    )
}
