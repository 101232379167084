import React from 'react'
import Message from "./Message";
import '../../css/common/Messages.css';


export default function AllMessages(props) {
   
    const listOfMessages = props.allMessages.map((msg, idx) => (
        <div key={idx}>
            <Message key={msg.id} msg={msg}/>
        </div>
    ))

    return (
        <div className="ChatMessages">
            {listOfMessages}
        </div>
    )
}
