import React from 'react'
import '../../css/web/WebSideBar.scss';

export default function GeneralInformation() {
    return (
        <div>
            <div className="InfoBoxSideBarTitle">
                Hej, Landy här!
            </div>
                
            <div className="InfoBoxSideBarText" >
                {/*<img src= {robot} className="width50 floatright" >
                    </img>*/
                    //Detta ska in i span: className="width50"
                  }
                <span > Här kan ställa frågor till mig! Har du en fråga om exempelvis 
                        hur man går tillväga för att börja spela golf, vad vi har för 
                        boendealternativ eller kanske är du intresserad av våra golfkurser?
                        Ställ din fråga till mig så hoppas jag kunna leda dig till rätt svar.
                </span>
                <p>
                    Kom ihåg att du alltid kan kontakta mina kollegor på kansliet om du inte får svar på dina frågor!
                </p>
                <div className="InfoBoxSideBarTextBold">
                <p> Telefon:   013-36 22 00 <br />
                    E-mail:   kansli@landerydsgolf.se </p>
                </div>
                <p>
                    Vi hörs i chatten!
                </p>
            </div>
        </div>
    )
}
