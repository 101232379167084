import React, {useState, useEffect} from 'react'


export default function AvailableBookingTimes(props) {
    
    const list = props.times.map((bookTime) => {
        var activeClassName = ""
        if (props.chosenTime == bookTime){
            activeClassName = " active"
        }
        return (
        <button onClick={ () => props.setchosenTime(bookTime) } type="submit" className={"BookingTimeButton" + activeClassName}>
            {bookTime}
        </button>
    )})


    return (
        <div className="AvailableBookingTimes">
            {list}
        </div>
    )
}
