import React, {useState, useEffect, useCallback} from 'react'
//import * as SocketIO from "socket.io-client";
import AddNewMeeting from './AddNewMeeting';
import BookingTime from "./BookingTime"
import BookingTimeToday from "./BookingTimeToday"

import Login from "./Login"
//var socket = SocketIO.connect();



export default function AdminDashboard() {

    const [futureBookings, setfutureBookings] = useState(false)
    const [historyBookings, sethistoryBookings] = useState(false)
    const [todaysBookings, settodaysBookings] = useState(false)
    const [showHistoryBookings, setshowHistoryBookings] = useState(false)
    const [loggedIn, setloggedIn] = useState(false)
    const [status, setstatus] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    if (!status) {
        //socket.emit("get_status", true)
    }

    useEffect(() => {
        /*
        socket.on('status', (status) => {
            setstatus(status)
        })*/
        
    }, [])

    async function getBookings() {
        try {
          const response = await fetch('/api/all_bookings');
          const data = await response.json();
          settodaysBookings(<div className="MeetingsContainer"> {data.todays_meetings.map((bookTime) => (
              <div className="Today">
                  <BookingTimeToday key={bookTime.id} bookingTimeInfo={bookTime} getBookings={getBookings} />
              </div>
          ))} </div>)
          setfutureBookings(<div className="MeetingsContainer"> {data.future_meetings.map((bookTime) => (
              <div className="Future">
                  <BookingTime key={bookTime.id} bookingTimeInfo={bookTime} getBookings={getBookings}/>
              </div>
          ))} </div>)
          sethistoryBookings(<div className="MeetingsContainer"> {data.history_meetings.map((bookTime) => (
              <div className="History">
                  <BookingTime key={bookTime.id} bookingTimeInfo={bookTime} getBookings={getBookings} />
              </div>
          ))} </div>)

          //console.log(data)

        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
    }

    useEffect(() => {
        getBookings();
      }, []);

    return (
        <div className="AdminPage">
            
            {status && <div>
                Status på hemsida: {status}
            </div>}
            {!loggedIn 
            ? <Login setloggedIn={setloggedIn}/>
            : <div>

                {isLoading && <div> Hämtar bokningar... </div>}

                {todaysBookings &&
                <div className="TodaysBookings">
                    <div className="Header">
                        Dagens möten
                    </div>
                    {todaysBookings}

                </div>}

                {historyBookings && <div>                   
                        {<button onClick={ () => setshowHistoryBookings(!showHistoryBookings) } type="submit" className="HistoryFutureButton">
                            {showHistoryBookings
                            ?   <span> Visa framtida möten </span>
                            :   <span> Visa historik </span>}
                        </button>}
                        {/*<button onClick={ () => getBookings() } type="submit" className="HistoryFutureButton">
                            <span> Uppdatera </span>
                            </button>*/}
                    </div>
                }
                <div className="MeetingsContainer">
                <div className="BookingTime">
                    <div className="Cell Important"> Datum </div>
                    <div className="Cell Important"> Tid</div>
                    <div className="Cell Important"> Max antal bokningar</div>
                    <div className="Cell Important"> Bokningar </div>
                </div>
                </div>
                {showHistoryBookings && <div> {historyBookings} </div>}
                
                {!showHistoryBookings && futureBookings}

                {!showHistoryBookings && <AddNewMeeting onSuccess={() => getBookings()}/>}
                        

            </div>}
        </div>
    )
}
