import React, {useState, useEffect} from 'react'
/*
import * as SocketIO from "socket.io-client";
var socket = SocketIO.connect();
*/
export default function EditBookingTime(props) {
    const [newBookerInfo, setnewBookerInfo] = useState(props.bookingTimeInfo)
    const [editMode, seteditMode] = useState(false)

    function shallowEqual(object1, object2) {
        if (object1 === object2) {
          return true;
        }
      
        if (!object1 || !object2) {
          return false;
        }
      
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
      
        if (keys1.length !== keys2.length) {
          return false;
        }
      
        for (let key of keys1) {
          const val1 = object1[key];
          const val2 = object2[key];
      
          if (val1 !== val2) {
            if (typeof val1 === "object" && typeof val2 === "object") {
              if (!shallowEqual(val1, val2)) {
                return false;
              }
            } else {
              return false;
            }
          }
        }
      
        return true;
      }
      
    async function saveChanges() {
        props.seteditMode(false)
        /*socket.emit(
            'save_changes_in_booking',
            {
              "new_booker_info": newBookerInfo,
            }
        )*/
        //props.getBookings()
        const response = await fetch('/api/update_booking', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newBookerInfo)
            });
            
        const response_data = await response.json();
        props.onSuccess()
    }

    function handleChangeInBooker (event, property) {
        const value = event.target.value;
        const index = event.target.name;

        setnewBookerInfo(prevState => {
            const newBookers = [...prevState.booker_info];
            newBookers[index] = {...newBookers[index], [property]: value};
            return {...prevState, booker_info: newBookers};
        });
    }
    

    function handleChange (event) {
        const value = event.target.value
        const property = event.target.name
        setnewBookerInfo({...newBookerInfo, [property]: value})
    }
    
    const bookerInfo = newBookerInfo.booker_info.map((booker, index) => (
        <div className="Meeting">
            <div className="Important">
                Möte {index+1}
            </div>
            <div className="PropertyRows">
            <div className="Property">
                <div >E-post:</div> <input 
                            type="text" 
                            className="EditInput"
                            name={index}
                            autoComplete="off"
                            value={booker.email}
                            onChange={(event) => handleChangeInBooker(event, "email")} 
                            id={"email" + index}/> 
            </div>
            <div className="Property">
            <div >Telefon:</div> <input 
                            className="EditInput"
                            type="text" 
                            name={index}
                            autoComplete="off"
                            value={booker.phone}
                            onChange={(event) => handleChangeInBooker(event, "phone")} 
                        id={"phone" + index}/> 
            </div>
            </div>
        </div>
    ))

    return (
        <div className="EditBookingTime">
            <form id="form">
            <div className="PropertyRows">
                <div className="Property"> <div>Tid:</div> <input 
                                className="EditInput"
                                type="text" 
                                name="time"
                                autoComplete="off"
                                value={newBookerInfo.time}
                                onChange={handleChange} 
                                id="time"/> 
                </div>
                <div className="Property"> <div>Max antal bokningar:</div> <input 
                                className="EditInput"
                                type="text" 
                                name="max_meetings"
                                autoComplete="off"
                                value={newBookerInfo.max_meetings}
                                onChange={handleChange} 
                                id="max_meetings"/> 
                </div>
            </div>
            {bookerInfo}
            
            {!shallowEqual(newBookerInfo, props.bookingTimeInfo)  && <div>
                <button onClick={ () => saveChanges()} type="button" className="SaveButton">
                    Spara
                </button>
            </div>}
            <div>
                <button onClick={ () => {setnewBookerInfo(props.bookingTimeInfo); props.seteditMode(false)}} type="button" className="EditShowButton">
                    Avbryt
                </button>
            </div>
            </form>
                
        </div>
    )
}
