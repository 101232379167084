import React from 'react'

export default function GDPRPopUp(props) {
    return (
        <div>
            {props.show && 
                <div className="PopUp"> 
                    <div className="PopUpTextContainer"> 
                        <div className="Header"> 
                            Välkommen till Landys hemsida!
                        </div>
                        <div className="Text"> 
                           Du har kommit hit genom att ha klickat på en unik länk som går att koppla till din e-postadress. 
                           För att du och andra ska få bra hjälp så snabbt som möjligt kommer konversationen att sparas och 
                           kopplas till din e-postadress.
                           Se därför till att inte ange några privata uppgifter om dig själv eller någon annan. 
                           Om du vill ha en konversation med Landy anonymt - klicka på Godkänn ej.
                           Om du godkänner att vi sparar din e-postadress och konversation för att utnyttja systemet till
                           dessa fulla potential klicka på Godkänn.   
                        </div>
                        <div className="ButtonsContainer">                    
                            {<button onClick={ () => {props.closePopUp();} } type="submit" className="ButtonNoEmail">
                                Godkänn
                            </button>}
                            {<button onClick={(e) => {e.preventDefault(); window.location.href='http://landy.landerydsgolf.se/';}} type="submit" className="ButtonNeedEmail">
                                Godkänn ej
                            </button>}
                            
                            
                        </div>
                    </div> 
                </div>}
        </div>
    )
}
