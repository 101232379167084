import React from 'react'
import '../../css/App.css'
import Facebook from '../../media/facebook2014.png';
import AAA from '../../media/aaa2015.png';

export default function Footer() {
    return (
        <div className="AppFooter">
            <div className="FooterRow">
                <a href="https://www.facebook.com/landerydsgolfklubb?fref=ts" target="_blank" rel="noopener noreferrer">
                    <img src={Facebook} alt="Följ oss på facebbok" width="88" height="35"/> </a> 
                <img src={AAA} alt="aaa2015" width="88" height="35"/>
            </div>
            <div className="FooterRow">
                <span> Utvecklat av Daniel Engelsons -  </span> <a className="Link" target="_blank"  href="https://daniel-engelsons-website.vercel.app/"> daniel.engelsons.org </a>
            </div>
            <div className="FooterRow">
                <span dangerouslySetInnerHTML={{ "__html": "&copy;" }} /> 2023 Landeryds Golfklubb i Linköping - Golfa i Östergötland 
            </div>
           
        </div>
    )
}
