import React from 'react'
import '../../css/common/Messages.css';

//import Iframe from 'react-iframe'

export default function Message(props) {
  const {id, text, from} = props.msg

  const urlify = (text) => {
    var urlRegex;
    if (text.includes("https://", 0)) {
      urlRegex = /(https?:\/\/[^\s]+)/g;
    }
    else {
      urlRegex = /(http?:\/\/[^\s]+)/g;
    }
    

    return text.replace(urlRegex, function(url) {
        return '<a  target="_blank" href="' + url + '">' + url.substring(0,30) + '... </a>';
    })  
  }

 
  if (from == 'Användare') {
    return (
        <div className="chatUserText">
          <p>
            {text}
          </p>
        </div>
    )
  }
  else {
    var aiText = text;
    if (text.includes("http", 0)){
      aiText = urlify(text)
    }       
       
    return (
      <div className="chatAiText" 
            dangerouslySetInnerHTML={{__html: '<p>' + aiText + '</p>'}}
          />
    )
  }
}
