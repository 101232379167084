import React, {useState, useEffect} from 'react'
//import * as SocketIO from "socket.io-client";
import AvailableBookingTime from './AvailableBookingTimes';
import { useParams } from 'react-router-dom';
//var socket = SocketIO.connect();

export default function BookingPage(props) {
    const { session } = useParams()

    const [availableBookings, setavailableBookings] = useState([])
    const [bookingInfo, setbookingInfo] = useState({session: session})
    const [message, setmessage] = useState("")
    const [chosenTime, setchosenTime] = useState(false)
    const [bookedMeeting, setbookedMeeting] = useState(false)
    const [GDPRApproved, setGDPRApproved] = useState(false)
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
          try {
            const response = await fetch('/api/available_bookings');
            const data = await response.json();
            setavailableBookings(data);
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false);
          }
        }
        fetchData();
      }, []);

    function handleChange (event) {
        const value = event.target.value
        const property = event.target.name
        setbookingInfo({...bookingInfo, [property]: value})
    }

    async function TryToBookMeeting() {
        //console.log(bookingInfo)
        if (!bookingInfo.email || !bookingInfo.phone || !chosenTime) {
            setmessage("Kunde ej boka möte. Se till att du fyllt i giltlig e-postadress, telefonnummer, valt en tid samt godkänt att dina uppgifter sparas.")
        }
        else {
            
            setmessage("Bokar möte...")
            //socket.emit("book_meeting", {...bookingInfo, time: chosenTime})
            const response = await fetch('/api/book_meeting', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...bookingInfo, 
                    time: chosenTime
                })
              });
              
            const response_data = await response.json();
            //console.log(response_data)
            if (response_data.success) { 

                setbookedMeeting(true)
            } else {
                setmessage("Kunde ej boka möte. Försök igen senare.")
            }
        }
    }
    
    var bookingsList;
    if (isLoading) {
        bookingsList = <div>Laddar...</div>
    }
    else if (availableBookings.length == 0) {
        bookingsList = <div>Det finns tyvärr inga lediga tider just nu</div>
    }
    else {
        bookingsList = <AvailableBookingTime times={availableBookings} setchosenTime={setchosenTime} chosenTime={chosenTime}/>
    }
    //console.log(bookingsList)
    return (
        <div className="BookingPage">
            {!bookedMeeting && <div>
            <div className="Header">
                Boka telefonmöte
            </div>

            <div className="PropertyRows">
                <div className="Property"> <div>E-postadress:</div> <input 
                                className="EditInput"
                                type="text" 
                                name="email"
                                autoComplete="off"
                                value={bookingInfo.email}
                                onChange={handleChange} 
                                id="email"/> 
                </div>
                <div className="Property"> <div>Telefonnummer:</div> <input 
                                className="EditInput"
                                type="text" 
                                name="phone"
                                autoComplete="off"
                                value={bookingInfo.phone}
                                onChange={handleChange} 
                                id="phone"/> 
                </div>
            </div>
            <div>
                Välj ett tidsintervall som vi kan ringa dig på:
            </div>
            <div>
                {bookingsList}
            </div>
            <div className="GDPRApprove">
                <input type="checkbox" checked={GDPRApproved} onChange={() => setGDPRApproved(!GDPRApproved)} />
                <div classname="checkboxText"> Jag godkänner att mina personuppgifter och eventuell tidigare konversation med Landy sparas och raderas senast en månad efter det bokade mötet. </div>
            </div>
            <div>
                <button onClick={ () => TryToBookMeeting() } type="submit" className="BookingButton">
                    Boka möte
                </button>
            </div>
            <div>
                {message}
            </div>
            </div>}

            {bookedMeeting && <div>
                <div className="Header">
                    Möte bokat!
                </div>
                <div className="Header">
                    Ett bekräftelsemejl har skickats till {bookingInfo.email}
                </div>
            </div>}

        </div>
    )
}
