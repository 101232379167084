import React, {useState, useEffect} from 'react'
/*
import * as SocketIO from "socket.io-client";
var socket = SocketIO.connect();
*/
export default function Login(props) {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [message, setmessage] = useState("")

    async function login() {
        /*socket.emit(
            'login_attempt',
            {
              "username": username,
              "password": password
            }
        )*/
        setmessage(<div>"Loggar in..."</div>)
        const response = await fetch('/api/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "username": username,
                "password": password
            })
            });
            
        const response_data = await response.json();
        if (response_data.success) { 
            props.setloggedIn(true)
        } else {
            setmessage(<div> 
                <div>
                    Felaktigt användarnamn eller lösenord.
                </div> 
                <div>
                    Kontakta daneng98@gmail.com om du glömt ditt lösenord.
                </div> 
            </div>)
        }
        
    }

    useEffect(() => {
        /*socket.on('login_response', (successful_login) => {      
            console.log(successful_login)     
            if (successful_login) {
                props.setloggedIn(true)
            }
            else {
                setmessage(<div> 
                    <div>
                        Felaktigt användarnamn eller lösenord.
                    </div> 
                    <div>
                        Kontakta daneng98@gmail.com om du glömt ditt lösenord.
                    </div> 
                </div>)
            }
        })*/
    }, [])


    return (
        <div className="Login">
            <div className="Header">
                    Logga in
            </div>
            
                <div className="Property">
                    <div >Användarnamn:</div> <input 
                                type="text" 
                                className="EditInput"
                                name="username"
                                autoComplete="off"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)} 
                                id="username"/> 
                </div>
                <div className="Property">
                <div >Lösenord:</div> <input 
                                className="EditInput"
                                type="password" 
                                name="password"
                                autoComplete="off"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)} 
                            id="password"/> 
                </div>
                
            
            <button id="submit" onClick={login} type="submit" className="button">
                        Logga in
                </button>
            {message}
        </div>
    )
}
