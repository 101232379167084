import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Bowser from "bowser"

const root = ReactDOM.createRoot(document.getElementById('root'));
const browser = Bowser.getParser(window.navigator.userAgent)

if (browser) {
    if (browser.parsedResult.browser.name == "Internet Explorer") {
        root.render(
            <div>
                <div>Du använder Internet Explorer som tyvärr inte stöds av denna applikation.</div>
                <div>Öppna länken i en modern webläsare som till exempel Michrosoft Edge, Google Chrome eller Safari</div>
                <div>Kom ihåg att du även kan chatta med Landy på vår hemsidan: 
                    <a href="https://www.landerydsgolf.se/" style={{color: "blue", textDecoration: "none"}}> 
                         Gå till hemsida 
                    </a>
                </div>
            </div>
            
        );
    }
    else {
        root.render(
            <App />
        );
    }
    
}
else {
    root.render(
        <App />
    );
}



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
