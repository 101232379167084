import React, { useState, useEffect} from 'react'
import AllMessages from '../common/AllMessages';
import '../../css/mobile/MobileContent.css';
import '../../css/App.css';

export default function MobileChatArea(props) {
    const [message, setmessage] = useState("");

    var inputRef;
    var endRef;
    useEffect(() => {
        if (!props.yesNoQuestion && props.buttonReplies.length == 0) {
            inputRef.focus()
        }
        endRef.scrollIntoView({block: "end", inline: "nearest", behavior: "smooth" });
    });

    return (
        <div className="MobileContent">
            <div className="MobilechatArea">
                  <AllMessages  allMessages={props.allMessages} />
            </div>
            {props.buttonReplies.length > 0 
                ? <div className="MobilechatAnswer YesNoAnswer">
                <p>Välj:</p>

                <div className="formInput" > 
                    {props.buttonReplies.map(button => {
                        return (
                            <button  onClick={(event) => {props.handleSubmit(event, button.message)}} type="submit" id="ButtonReply">
                                {button.title}
                            </button>
                        )
                    })}
                </div>
                </div>
                
            
            
                : props.yesNoQuestion 
                    ? <div className="MobilechatAnswer YesNoAnswer">
                        <p > Svara Ja om du fick hjälp av detta eller Nej om du fortfarande behöver hjälp</p>
                        <div className="formInput" > 
                            <button  onClick={(event) => {props.handleSubmit(event, "Ja")}} type="submit" id="YesNobutton">
                                Ja
                            </button>
                            <button onClick={(event) => {props.handleSubmit(event, "Nej")}} type="submit" id="YesNobutton">
                                Nej
                            </button>
                        </div>
                        
                    </div>
                    :
                    <div className="MobilechatAnswer">
                        <p>Skriv här nedan:</p>
                        <div className="formInput"> 
                        <form id="form">
                                <input 
                                        ref={(input) => inputRef=input} 
                                        type="text" 
                                        name="message"
                                        autoComplete="off"
                                        value={message}
                                        onChange={(event) => setmessage(event.target.value)}
                                        id="text"/>
                                <button id="submit" onClick={(event) => {setmessage(""); props.handleSubmit(event, message)}} type="submit" className="button">
                                    Skicka!
                                </button>
                            </form>
                        </div>
                    </div>
            }
            <div ref={(input) => endRef=input} style={{ float:"left", clear: "both" }}></div>
        </div>
    )
}
