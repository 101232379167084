import React, { useState } from 'react'
import MediaQuery from 'react-responsive'
import MobileChatArea from '../mobile/MobileChatArea'
import Footer from './Footer'
import WebChatArea from '../web/WebChatArea'
import WebRightSideBar from '../web/WebRightSideBar'
import { useParams } from 'react-router-dom';
import AskIfEmailNeededPopUp from './AskIfEmailNeededPopUp'
import GDPRPopUp from './GDPRPopUp'
export default function MainContent({handleSubmit, allMessages, yesNoQuestion, buttonReplies, showAskIfEmailNeededPopUp,setshowAskIfEmailNeededPopUp}) {
    const { user_code } = useParams();
    const [showGDPRPopUp, setshowGDPRPopUp] = useState(user_code)
    return (
    <React.Fragment>
        <GDPRPopUp              show={showGDPRPopUp} 
                                closePopUp={() => setshowGDPRPopUp(false)}
                                user = {user_code}/>

        <AskIfEmailNeededPopUp  show={showAskIfEmailNeededPopUp} 
                                closePopUp={() => setshowAskIfEmailNeededPopUp(false)}
                                user = {user_code}/>

        <MediaQuery query="(max-width: 950px)">
            <MobileChatArea handleSubmit = { (e, msg) => handleSubmit(e, msg, user_code)} 
                            allMessages={allMessages} 
                            yesNoQuestion = {yesNoQuestion}
                            buttonReplies = {buttonReplies}
            />
            <Footer/>
        </MediaQuery>

        <MediaQuery query="(min-width: 950px)">

                <div className="bodyWithOutFooter">
                
                <WebChatArea  handleSubmit = { (e, msg) => handleSubmit(e, msg, user_code)} 
                                allMessages={allMessages} 
                                yesNoQuestion = {yesNoQuestion}
                                buttonReplies = {buttonReplies}
                            />    
                <WebRightSideBar />
                </div>               

                <Footer/>

        </MediaQuery>
    </React.Fragment>
  )
}
